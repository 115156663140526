.login-form-container {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form {
    width: 100%;
    margin-top: 8px;
}

.login-button {
    margin: 24px 0px 16px !important;
}

.login-forget-password {
    text-decoration: none;
}
