.forget-password-form-container {
    margin-top: 8rem !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.forget-password-form {
    width: 100% !important;
    margin-top: 8px !important;
}

.forget-password-button {
    margin: 24px 0 16px !important;
}

.forget-password-sign-in {
    text-decoration: none !important;
}
