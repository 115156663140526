.statusActive {
    color: green;
}

.statusBlocked {
    color: red;
}

.react-datepicker-popper {
    z-index: 10!important;
}

.custom-date-picker{
    width: 100%!important;
    padding-top: 10.5px!important;
    padding-bottom: 10.5px!important;
    border-radius: 4px!important;
    padding: 10.5px 14px!important;
    font-size: 15.8px!important;
    border-color: rgba(0, 0, 0, 0.23)!important;
}

.custom-date-picker:focus { 
    outline: none!important;
    border-color: #1e7bbb!important;
 }
